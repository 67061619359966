import jwtt from "jwt-encode";
import IframeResizer from "iframe-resizer-react";

import { useOne } from "@pankod/refine-core";
import { IMetabaseEmbed } from "interfaces";

import {
    Card,    
} from "@pankod/refine-mui"

export const IssuePage = () => {

  const metabaseEmbed = useOne<IMetabaseEmbed>({
    dataProviderName: "zordonprovider",
    resource: "dashboard",
    id: "issue",
  })

  return (
      <Card style={{height: "100%", background: "#fff"}}>
        {!metabaseEmbed.isLoading &&
          <IframeResizer
            log
            heightCalculationMethod="documentElementScroll"
            height={"100%"}
            width={"100%"}
            scrolling={true}
            src={metabaseEmbed.data?.data.url}
            frameBorder="0"
          /> }
      </Card>
    );
  };
  