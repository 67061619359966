import { useUpdate } from "@pankod/refine-core";
import { ICompany } from "interfaces";

export const useAttachDomain = () => {
  const { mutate: attachDomain } = useUpdate<ICompany>();

  const attachDomainMutation = (company: ICompany) => {
    attachDomain({
      resource: "zordon/manage/attach-domain",
      dataProviderName: "researchprovider",
      id: company.id,
      values: { recreate: true },
    });
  };

  return { attachDomainMutation };
};