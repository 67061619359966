import { AuthProvider } from "@pankod/refine-core";
import { redirectPage } from "@pankod/refine-core/dist/definitions/helpers";
import axios, { AxiosRequestConfig, HeadersDefaults } from "axios";

interface CommonHeaderProperties extends HeadersDefaults {
  Token: string;
}

export const TOKEN_KEY = "zordon-auth";

const zordonAuthProvider = (apiUrl: string) => {
  const axiosInstance = axios.create();

  const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
      var payload = { username, password };

      var config: AxiosRequestConfig = {
        method: "post",
        url: apiUrl + "/auth/v1/login/zordon",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.5",
          "Accept-Encoding": "gzip, deflate, br",
          "Content-Type": "application/json;charset=utf-8",
          Connection: "keep-alive",
          "Sec-Fetch-Dest": "empty",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-site",
          "Access-Control-Allow-Origin": "*",
        },
        data: JSON.stringify(payload),
      };

      const { data, status } = await axios(config);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.data.token);
        localStorage.setItem("role", data.data.role);
        // set header axios instance
        axiosInstance.defaults.headers = {
          Token: `${data.data.token}`,
        } as CommonHeaderProperties;
        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem("role");
      localStorage.removeItem(TOKEN_KEY);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const role = localStorage.getItem("role");
      if (token && role !== null) {
        axiosInstance.defaults.headers = {
          Token: `${token}`,
        } as CommonHeaderProperties;
        return Promise.resolve();
      }
      return Promise.reject();
    },
    getPermissions: () => {
      const role = localStorage.get("role");
      return Promise.resolve(role);
    },
    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        return Promise.reject();
      }
  
      return Promise.resolve({
        id: 1,
      });
    },
  };

  return {
    authProvider,
    axiosInstance,
  };
};

export default zordonAuthProvider;
