import zordonDataProvider from "providers/zordonDataProvider";
import { ResearchDataProvider } from "providers/researchDataProvider";
import ImageDataProvider from "providers/imageDataProvider";
import CourseDataProvider from "providers/courseDataProvider";
import ArticleDataProvider from "providers/articleDataProvider";
import FeedDataProvider from "providers/feedDataProvider";
import zordonAuth from "providers/zordonAuthProvider";
import FlagDataProvider from "providers/flagDataProvider";

import restProvider from "@pankod/refine-simple-rest";
import restapi from "./rest-api"

var API_URL = "";
var FILE_API_URL = "";

if (window.location.hostname.includes("zordon.codemi.co.id") === true) {
    API_URL = "https://api-id.codemi.co.id";
    FILE_API_URL = "https://files-id.codemi.co.id";
} else if (window.location.hostname.includes("zordon.pawonmburi.com")) {
    API_URL = "https://api.staging.pawonmburi.com";
    FILE_API_URL = "https://files.staging.pawonmburi.com";
} else if (window.location.hostname.includes("zordon.production.lvh.me")) {
    API_URL = "https://api-id.codemi.co.id";
    FILE_API_URL = "https://files-id.codemi.co.id";
} else if (window.location.hostname.includes("zordon.staging.lvh.me")) {
    API_URL = "https://api.staging.pawonmburi.com";
    FILE_API_URL = "https://files.staging.pawonmburi.com";
} else {
    API_URL = "https://api.staging.pawonmburi.com";
    FILE_API_URL = "https://files.staging.pawonmburi.com";
}

const { authProvider, axiosInstance } = zordonAuth(API_URL);
const dataProvider = zordonDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "companies"
);

const bulkinvite = ResearchDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "users"
);

const imagedataprovider = ImageDataProvider(
    `${FILE_API_URL}`,
    axiosInstance,
    "users"
);

const zordonprovider = restProvider(
    `${API_URL}/api/v1/research/zordon`,
    axiosInstance
);

const researchprovider = restProvider(
    `${API_URL}/api/v1/research`,
    axiosInstance
);

const codemiapiproviderv1 = restapi(
    `${API_URL}/api/v1`,
    axiosInstance
);

const courseprovider = CourseDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "course"
)

const articleprovider = ArticleDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "post"
)

const feedprovider = FeedDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "post"
)

const flagprovider = FlagDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "flag"
)

// Export all providers
export default {
    providers: {
        default: dataProvider,
        authProvider: authProvider,
        zordonprovider: zordonprovider,
        imagedataprovider: imagedataprovider,
        researchprovider: researchprovider,
        courseprovider: courseprovider,
        articleprovider: articleprovider,
        feedprovider: feedprovider,
        flagprovider: flagprovider,
        bulkinvite: bulkinvite,
        codemiapiproviderv1
    }
};