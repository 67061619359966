import React, { useCallback, useEffect, useRef, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { RgbColor, RgbColorPicker } from "react-colorful";

interface Ipop {
  color?: RgbColor;
  onChange?: any;
}



export const PopoverPicker: React.FC<Ipop> = ({
  color = {
    r: 0,
    g: 0,
    b: 0,
  },
  onChange,
}) => {
  const [isOpen, toggle] = useState(false);


  const close = useCallback(() => toggle(false), []);

  const styles = {
    color: {
      borderRadius: "2px",
    },
    swatch: {
      padding: "5px",
      width: "4em",
      height: "4em",
      background: "#fff",
      borderRadius: "1px",
      display: "inline-block",
      cursor: "pointer",
      border: "1px solid #dfdfdf",
      boxShadow: "1px -1px 16px -1px rgba(0,0,0,0.1)",
      WebkitBoxShadow: "1px -1px 16px -1px rgba(0,0,0,0.1)",
      MozBoxShadow: "1px -1px 16px -1px rgba(0,0,0,0.1)"
    }
  };

  const popoverCSS = {
    position: "absolute",
    zIndex: "2",
  } as React.CSSProperties;

  const coverCSS = {
    Position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  } as React.CSSProperties;

  
  return (
    <>
        <div
          style={{
            ...styles.swatch,
            background: `${color}`,
          }}
          onClick={() => toggle(!isOpen)}
        >
        </div>
      {isOpen ? (
        <div style={popoverCSS}>
          <div style={coverCSS} onClick={close} />
          <SketchPicker disableAlpha  color={color} onChange={(clr)=> {
            close()
            onChange(clr)
          }} />
        </div>
      ) : null}
    </>
  );
};

export default PopoverPicker;
