import * as React from "react";
import { LoginPageProps, RegisterFormTypes } from "@pankod/refine-core";
import { FormProvider, useForm } from "@pankod/refine-react-hook-form";
import {
    Button,
    BoxProps,
    Box,
    Checkbox,
    Container,
    Card,
    CardContent as MuiCardContent,
    CardContentProps,
    FormControlLabel,
    TextField,
    Typography,
    Divider,
    Link as MuiLink,
} from "@mui/material";

import {
    BaseRecord,
    HttpError,
    useLogin,
    useTranslate,
    useRouterContext,
    
} from "@pankod/refine-core";
import { layoutStyles, titleStyles } from "./styles";
import { UseFormProps } from "@pankod/refine-react-hook-form";

export interface FormPropsType extends UseFormProps {
    onSubmit?: (values: RegisterFormTypes) => void;
}

export interface LoginFormTypes {
    username?: string;
    password?: string;
    remember?: boolean;
    providerName?: string;
    redirectPath?: string;
}

type LoginProps = LoginPageProps<BoxProps, CardContentProps, FormPropsType>;

/**
 * login will be used as the default type of the <AuthPage> component. The login page will be used to log in to the system.
 * @see {@link https://refine.dev/docs/api-reference/mui/components/mui-auth-page/#login} for more details.
 */
export const LoginPage: React.FC<LoginProps> = ({
    providers,
    registerLink,
    forgotPasswordLink,
    rememberMe,
    contentProps,
    wrapperProps,
    renderContent,
    formProps,
}) => {
    const { onSubmit, ...useFormProps } = formProps || {};
    const methods = useForm<BaseRecord, HttpError, LoginFormTypes>({
        ...useFormProps,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;

    const { mutate: login, isLoading } = useLogin<LoginFormTypes>();
    const translate = useTranslate();
    const { Link } = useRouterContext();

    const renderProviders = () => {
        if (providers && providers.length > 0) {
            return (
                <>
                    {providers.map((provider: any) => {
                        return (
                            <Button
                                key={provider.name}
                                fullWidth
                                variant="outlined"
                                sx={{
                                    my: "8px",
                                    textTransform: "none",
                                }}
                                onClick={() =>
                                    login({ providerName: provider.name })
                                }
                                startIcon={provider.icon}
                            >
                                {provider.label}
                            </Button>
                        );
                    })}
                    <Divider style={{ fontSize: 12 }}>
                        {translate("pages.login.divider", "or")}
                    </Divider>
                </>
            );
        }
        return null;
    };

    const CardContent = (
        <Card {...(contentProps ?? {})}>
            <MuiCardContent sx={{ paddingX: "32px" }}>
                <Box
                    component="form"
                    onSubmit={handleSubmit((data) => {
                        if (onSubmit) {
                            onSubmit(data);
                        }
                        
                        return login(data);
                    })}
                    gap="16px"
                >
                    <TextField
                        {...register("username", {
                            required: true,
                        })}
                        id="username"
                        margin="normal"
                        fullWidth
                        label={translate("pages.login.fields.username", "Username")}
                        error={!!errors.username}
                        name="username"
                        type="username"
                        autoComplete="username"
                    />
                    <TextField
                        {...register("password", {
                            required: true,
                        })}
                        id="password"
                        margin="normal"
                        fullWidth
                        name="password"
                        label={translate(
                            "pages.login.fields.password",
                            "Password",
                        )}
                        helperText={errors?.password?.message}
                        error={!!errors.password}
                        type="password"
                        placeholder="●●●●●●●●"
                        autoComplete="current-password"
                    />

                    <Box
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: "8px",
                        }}
                        disabled={isLoading}
                    >
                        {translate("pages.login.signin", "Sign in")}
                    </Button>
                </Box>
            </MuiCardContent>
        </Card>
    );

    return (
        <FormProvider {...methods}>
            <Box component="div" style={layoutStyles} {...(wrapperProps ?? {})}>
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100vh",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {renderContent
                            ? renderContent(CardContent)
                            : CardContent}
                    </Box>
                </Container>
            </Box>
        </FormProvider>
    );
};
