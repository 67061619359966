import React, { useRef } from "react";
import {
    IResourceComponentsProps,
    useRouterContext,
    useOne
} from "@pankod/refine-core";

import {
    Paper,
    Grid,
    Typography,
    Divider,
    Skeleton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@pankod/refine-mui";

import { Add, ArrowCircleRightTwoTone, ArrowCircleLeftTwoTone } from "@mui/icons-material";
import { stripHtml } from 'string-strip-html';
import { Carousel, Image } from "@pankod/refine-antd";

import { IArticleList } from "interfaces";
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

export interface IParams {
    id: string;
}

export const ArticleDetail: React.FC<IResourceComponentsProps> = () => {

    const { useParams } = useRouterContext();
    const params = useParams() as IParams;
    const slider: any = useRef(null);

    let dataDetail: any = useOne<IArticleList>({
        dataProviderName: "articleprovider",
        resource: "auditor/blog",
        id: params.id,
    });

    const detail = dataDetail.data?.data

    return (
        <>
            <Paper style={{ padding: 30, marginBottom: 30 }}>
                <Typography variant='h6' mb={5}><strong>Article Detail</strong></Typography>
                <Grid container spacing={4}>
                    <Grid item lg={3}>
                        <Typography sx={{ fontWeight: 550 }}>Article Title</Typography>
                    </Grid>
                    <Grid item lg={9}>
                        {detail ? detail?.title
                            :
                            <Skeleton variant="rectangular" />}
                    </Grid>

                    <Grid item lg={3}>
                        <Typography sx={{ fontWeight: 550 }}>Description</Typography>
                    </Grid>
                    <Grid item lg={9}>
                        {detail ? detail?.short_description
                            :
                            <Skeleton variant="rectangular" />}
                    </Grid>

                    <Grid item lg={3}>
                        <Typography sx={{ fontWeight: 550 }}>Thumbnail Image</Typography>
                    </Grid>
                    <Grid item lg={9}>
                        {detail?.media ?
                            <Image style={{ maxWidth: '100%', height: 'auto' }} src={detail?.media.images[0]} alt='article-img' /> : detail ? 'No Images'
                                :
                                <Skeleton variant="rectangular" />}
                    </Grid>

                    <Grid item lg={3}>
                        <Typography sx={{ fontWeight: 550 }}>Content</Typography>
                    </Grid>
                    <Grid item lg={9}>
                        {detail ?
                            <p dangerouslySetInnerHTML={{
                                __html: decode(DOMPurify.sanitize(detail?.content)),
                            }}
                            />
                            :
                            <Skeleton variant="rectangular" />}
                    </Grid>
                    <Grid item lg={12}>
                        <Divider sx={{ borderColor: 'whitesmoke', borderBottomWidth: 'thick', marginBottom: '2em' }} />
                        <Typography variant='h6'><strong>Article Properties</strong></Typography>
                    </Grid>

                    <Grid item lg={3}>
                        <Typography sx={{ fontWeight: 550 }}>Category</Typography>
                    </Grid>
                    <Grid item lg={9}>
                        {detail ? detail.categories?.map((v: any) => v?.name).join() || '-'
                            :
                            <Skeleton variant="rectangular" />}
                    </Grid>
                    <Grid item>
                        <div>
                            {detail && detail.sections &&
                                <Typography variant='h6' gutterBottom>
                                    <strong>Section</strong> ({detail?.sections.length > 0 ? detail?.sections.length : 0})
                                </Typography>}

                            {detail && detail?.sections && detail.sections.length > 0 && detail.sections.map((d: any, i: number) => (
                                <Accordion key={i} sx={{ minWidth: '80vw' }}>
                                    <AccordionSummary
                                        expandIcon={<Add />}
                                        sx={{ flexDirection: 'row-reverse', }}
                                        aria-controls={`${i}-content`}
                                        id={`${i}-header`}
                                    >
                                        <Typography>{d.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ backgroundColor: '#f0f2f3'}}>
                                        <div>
                                                {stripHtml(decode(d.content)).result}
                                                {d.embed.medias && d.embed.medias.length > 0 &&
                                                    <div style={{ marginTop: 20 }}>
                                                        {d.embed.medias.length > 1 && <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                position: 'relative',
                                                                top: '20em',
                                                                zIndex: 99,
                                                                marginRight: 10,
                                                                marginLeft: 10,
                                                            }}>
                                                            <ArrowCircleLeftTwoTone fontSize='large' sx={{ cursor: 'pointer' }}
                                                                htmlColor='whitesmoke' onClick={() => slider.current.prev()} />

                                                            <ArrowCircleRightTwoTone fontSize='large' sx={{ cursor: 'pointer' }}
                                                                htmlColor='whitesmoke' onClick={() => slider.current.next()} />
                                                        </div>}
                                                        <Carousel ref={slider}
                                                            dotPosition='top'>
                                                            {d.embed.medias.map((dd: any, index: number) => {
                                                                return (
                                                                    <div>
                                                                        {dd.type === 'images' && <Image src={dd.url} alt={`image-${index}`} />}
                                                                        {dd.type === 'video' && <video style={{ maxWidth: '100%', height: 'auto' }} controls>
                                                                            <source src={dd.url} />
                                                                        </video>}
                                                                    </div>
                                                                )
                                                            })}
                                                        </Carousel>
                                                    </div>
                                                }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
