import React from "react";
import {
    IResourceComponentsProps,
    useRouterContext,
    useOne
} from "@pankod/refine-core";

import {
    Paper,
    Accordion, AccordionSummary, AccordionDetails,
    Grid,
    Typography,
    Chip,
    Divider,
    Dialog,
    Skeleton,
} from "@pankod/refine-mui";

import {
    Add,
    PictureAsPdf,
    PlayArrow,
    YouTube,
    Html,
    CardMembership,
    BarChart,
    Audiotrack,
    Keyboard,
    Bolt,
    CheckCircle,
    Cloud
} from "@mui/icons-material";
import { ICourseDetail } from "interfaces";
import DOMPurify from 'dompurify';

export interface IParams {
    id: string;
}

export const CourseDetail: React.FC<IResourceComponentsProps> = () => {

    const { useParams } = useRouterContext();
    const params = useParams() as IParams;

    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState('');
    const [fileType, setFileType] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let dataDetail: any = useOne<ICourseDetail>({
        dataProviderName: "courseprovider",
        resource: "course/get",
        id: params.id,
    });

    const colorStatusMap: { [key: string]: any } = {
        need_approval: "#F9B300",
        inactive: "#929292",
        active: "#38A169",
        approved: "#38A169",
        scheduled: "#0089F9",
        rejected: "#FF3C35",
    };

    const labelStatusMap: { [key: string]: any } = {
        need_approval: "Need Approval",
        inactive: "Inactive",
        active: "Active",
        approved: "Active",
        scheduled: "Scheduled",
        rejected: "Rejected",
    };

    const renderIcon = (track: string, fileUrl: string) => {
        if (track === 'question') {
            return <Bolt />;
        }
        if (track === 'user_checklist') {
            return <CheckCircle />;
        }
        if (track === 'video') {
            return <PlayArrow
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    handleOpen()
                    setFileType('video')
                    setFile(fileUrl)
                }} />;
        }
        if (track === 'youtube') {
            return <YouTube />;
        }
        if (track === 'soundcloud') {
            return <Cloud />;
        }
        if (track === 'html5') {
            return <Html />;
        }
        if (track === 'pdf') {
            return <PictureAsPdf
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    handleOpen()
                    setFileType('pdf')
                    setFile(fileUrl)
                }}
            />;
        }
        if (track === 'accreditation') {
            return <CardMembership />;
        }
        if (track === 'user_survey') {
            return <BarChart />;
        }
        if (track === 'audio') {
            return <Audiotrack />;
        }
        if (track === 'freetext') {
            return <Keyboard />;
        }

        return '';
    };

    const detail = dataDetail.data?.data

    return (
        <>
            <Paper style={{ padding: 30, marginBottom: 30 }}>
                <Grid container spacing={4}>
                    <Grid item lg={4}>
                        {detail ?
                            <img style={{ maxWidth: '100%', height: 'auto' }} src={detail?.image === '' ?
                                'https://files.staging.pawonmburi.com/files/learning_staging/image/16ca6ef4ac1ecc4ca9261079d72b06b7_default_course_image.png' : detail?.image} alt='course-img' />
                            :
                            <Skeleton variant="rectangular" height={200} />}
                    </Grid>
                    <Grid item lg={8}>
                        {detail ?
                            <>
                                <Typography variant='h4' gutterBottom><strong>{detail?.name}</strong></Typography>
                                <Chip label={labelStatusMap[detail?.status]}
                                    style={{ color: 'white', backgroundColor: colorStatusMap[detail?.status], marginBottom: 10, }} />
                                <div><strong>{detail?.type === 'e_learning' ? 'Self Learning' : 'In-Class Learning'}</strong></div>
                            </>
                            :
                            <>
                                <Skeleton height={60} />
                                <Skeleton height={30} width={30} />
                                <Skeleton width={50} />
                            </>
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={{ padding: 30 }}>
                <Typography variant="h6" >Course Description</Typography>
                {detail ?
                    <p
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(detail?.description),
                        }}
                    />
                    : <Skeleton />}
                <div>
                    {detail ?
                        <Typography variant='subtitle1' mt={5} gutterBottom>Curriculum ({detail?.curriculum.length > 0 ? detail?.curriculum.length : 0})</Typography>
                        : <Skeleton width={30} />}

                    {detail ?
                        detail?.curriculum && detail.curriculum.length > 0 ? detail.curriculum.map((d: any, i: number) => (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<Add />}
                                    sx={{ flexDirection: 'row-reverse', }}
                                    aria-controls={`${i}-content`}
                                    id={`${i}-header`}
                                >
                                    <Typography>{d.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: '#f0f2f3', paddingTop: 0, paddingBottom: 0 }}>
                                    {d.detail.length > 0 && d.detail.map((dd: any) => (
                                        <div>
                                            <Divider light />
                                            <Grid
                                                container
                                                alignItems="center"
                                                pb={2} pt={2}
                                            >
                                                {renderIcon(dd.file_type, dd.file)}
                                                <span style={{ marginLeft: 10 }}>{dd.description}</span>
                                            </Grid>
                                        </div>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )) :
                            <Typography>Sorry, no curriculum can be found here 😓</Typography>
                        :
                        <Skeleton height={50} />}
                </div>
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={fileType === 'pdf'}
                maxWidth='lg'
            >
                {
                    fileType === 'video' ?
                        <video controls >
                            <source src={file} type='video/mp4' />
                        </video>
                        :
                        <div style={{ height: '90vh' }}>
                            <iframe
                                src={file}
                                // type="application/pdf"
                                height="100%"
                                width="100%"
                            ></iframe>
                        </div>
                }
            </Dialog>
        </>
    );
};
