import { useUpdate } from "@pankod/refine-core";
import { ICompany } from "interfaces";

export const useDetachDomain = () => {
  const { mutate } = useUpdate<ICompany>();

  const onClickDetachDomain = (company: ICompany) => {
    mutate({
      resource: "zordon/manage/clear-domain",
      dataProviderName: "researchprovider",
      id: company.id,
      values: { recreate: true },
    });
  };

  return { mutate, onClickDetachDomain };
};