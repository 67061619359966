import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";
import {
    DataProvider,
    HttpError,
    CrudOperators,
    CrudFilters,
} from "@pankod/refine-core";

import { CrudSorting } from "@pankod/refine-core/dist/interfaces";
import { ICompanyConfig, ICompanyInfo, ICompanyGetOne } from "interfaces";
import moment from "moment";

const axiosInstance = axios.create();
var resourceCache:any = {};
var singleCompanyCache:any = {};

export interface INewCompany {
    name: string
}


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

const getHostnameFromRegex = (url:any) => {
    // run against regex
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1];
  }

  

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "ne":
        case "gte":
        case "lte":
            return `_${operator}`;
        case "contains":
            return "_like";
        case "eq":
            return "q"
        default:
            return "";
    }
};

const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        const _sort: string[] = [];
        const _order: string[] = [];

        sort.map((item) => {
            _sort.push(item.field);
            _order.push(item.order);
        });

        return {
            _sort,
            _order,
        };
    }

    return;
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    if (filters) {
        filters.map(({ operator, value}) => {
            // if (field === "q") {
            //     queryFilters[field] = value;
            //     return;
            // }

            const mappedOperator = mapOperator(operator);
            queryFilters[`${mappedOperator}`] = value;
        });
    }

    return queryFilters;
};



export const ResearchDataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
    responseKey: string = "data"
): DataProvider => ({
    getList: async ({ resource, pagination, filters, sort }) => {
        const url = `${apiUrl}/${resource}`;

        // pagination
        const current = pagination?.current || 1;
        const pageSize = pagination?.pageSize || 10;

        const queryFilters = generateFilter(filters);

        const query: {
            _sort?: string;
            _order?: string;
            page: number;
            per_page: number;
        } = {
            page: current,
            per_page: pageSize
        };

        // const generatedSort = generateSort(sort);
        // if (generatedSort) {
        //     const { _sort, _order } = generatedSort;
        //     query._sort = _sort.join(",");
        //     query._order = _order.join(",");
        // }

        const { data, headers } = await httpClient.get(
            `${url}?${stringify(query)}&${stringify(queryFilters)}`,
        );

        // const total = +headers["x-total-count"];
        resourceCache[resource] = data["data"][responseKey]

        return {
            data : data["data"][responseKey] ,
            total : data["data"]["total_page"] * pageSize,
        };
    },

    getMany: async ({ resource, ids }) => {
        const { data } = await httpClient.get(
            `${apiUrl}/${resource}?${stringify({ id: ids })}`,
        );

        return {
            data,
        };
    },


    create: async ({ resource, variables }) => {
        const url = `${apiUrl}/company/create`;

        const normalizeForm = (value: any) => {
            const admin = {
              name: value.admin_name,
              email: value.admin_email,
              password: value.admin_password,
              username: value.admin_username
            }
          
            const company = {
              city: "Jakarta Pusat",
              country: "IDN",
              course_creator: ["admin"],
              post_creator: ["user", "moderator", "admin"],
              province: "DKI Jakarta",
              status: "trial",
              street: "Jalan Daan Mogot",
              zip: "55213",
              capacity: 100000,
              background: value.background[0].url,
              domain: value.domain,
              favicon: value.favicon[0].url,
              icon: value.icon[0].url,
              logo: value.logo[0].url,
              user_picture: value.user_picture[0].url,
              accent_color: value.accent_color,
              company_colors: [value.accent_color],
              email: value.email,
              phone: value.phone,
              name: value.name,
              page_title: value.page_title,
              subtitle: value.subtitle,
              customer_type: value.customer_type,
              expired_at: moment(value.expired_at)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
              })
              .format(),
              codemi_cs: value.codemi_cs
            };

            const learninghub = {
                domain: value.learninghub.domain,
                company_id: "",
                status: value.learninghub.status ? "active" : "inactive",
              }
          
            return {admin, company, learninghub};
          };

        const { data } = await httpClient.post(url, normalizeForm(variables));
        return {
            data,
        };
    },

    createMany: async ({ resource, variables }) => {
        const response = await Promise.all(
            variables.map(async (param) => {
                const { data } = await httpClient.post(
                    `${apiUrl}/${resource}`,
                    param,
                );
                return data;
            }),
        );

        return { data: response };
    },

    // companyRoutes.Put("/config/:company_id",
    // auth.HasRole("super_admin", "admin", "group_admin"),
    // company_config.UpdateByCompanyID)


    update: async ({ resource, id, variables }) => {
      if(resource === "company"){
        const oldConfig = singleCompanyCache[`${apiUrl}/company/get/${id}`]
        const resCompanyInfoUpdate = await httpClient.put(`${apiUrl}/company/update/${id}`, {...oldConfig, ...variables});
      }
      
      const defaultResource = `${resource === "company" ? "enable_config" : "update-status"}`;
      const resEnableConfig = await httpClient.put(`${apiUrl}/company/${defaultResource}/${id}`, variables);
      
        return {
            data: resEnableConfig['data']
        };
    },

    updateMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    getOne: async ({ resource, id }) => {
        
        const url = `${resource}`;

        // const compSingle = resourceCache[url].filter((company:any) => company.id === id)[0]
        
        const companyEnableConfig = await httpClient.get(
            `${apiUrl}/company/enable_config/${id}`
        );

   

        const companySingleConfig = await httpClient.get(
            `${apiUrl}/company/get/${id}`
        );
        const compInfo:ICompanyInfo = companySingleConfig["data"]["data"]

             const companyConfig = await httpClient.get(
            `${apiUrl}/company/login-config/${getHostnameFromRegex(compInfo.domain)}`
        );

        singleCompanyCache[`${apiUrl}/company/get/${id}`] = companySingleConfig['data']['data']

        const compConfig = companyEnableConfig["data"]["data"]
        const compSingleCfg:ICompanyGetOne = companyEnableConfig["data"]["data"]


        const configs_data = []

        for (const [key, value] of Object.entries(compConfig)) {
            key.includes("enable") && 
            configs_data.push({name:key, value:value})
            }

        return {
            data:{...companySingleConfig['data']['data'], ...compConfig, configs: configs_data, compinfo: companyConfig['data']['data']},
        };
    },

    deleteOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url);

        return {
            data,
        };
    },

    deleteMany: async ({ resource, ids }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        let requestUrl = `${url}?`;

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const { _sort, _order } = generatedSort;
                const sortQuery = {
                    _sort: _sort.join(","),
                    _order: _order.join(","),
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url);
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});

export default ResearchDataProvider;
