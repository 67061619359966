import React from "react";
import {
  useTranslate,
  useNavigation,
  useModal,
  useShow,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  Avatar,
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  DateField,
  BooleanField,
  TextFieldComponent,
  Paper,
  TextField,
} from "@pankod/refine-mui";
import { EditOutlined, ViewComfyAltOutlined } from "@mui/icons-material";

import { IBulkInvite, IOrderStatus, IQueue } from "interfaces";

import { OrderStatus } from "components/orderStatus";

export const TaskQueueList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { edit } = useNavigation();

  const { show, visible, close } = useModal();

  const { queryResult, setShowId } = useShow<IBulkInvite>();

  const { data: showQueryResult } = queryResult;
  const record = showQueryResult?.data;

  const { dataGridProps } = useDataGrid<IQueue>({
    initialPageSize: 20,
    dataProviderName: "bulkinvite",
  });

  const columns = React.useMemo<GridColumns<IQueue>>(
    () => [
      {
        field: "id",
        align: "center",
        headerName: t("ID"),
      },
      // {
      //     field: "title",
      //     headerName: t("stores.fields.title"),
      //     flex: 1,
      //     minWidth: 160,
      // },
      // {
      //     field: "email",
      //     headerName: t("stores.fields.email"),
      //     flex: 2,
      //     minWidth: 300,
      // },
      // {
      //     field: "gsm",
      //     headerName: t("stores.fields.gsm"),
      //     flex: 1,
      //     minWidth: 150,
      // },
      {
        field: "user_id",
        headerName: "User ID",
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.user_id} />;
        },
      },
      {
        field: "createdAt",
        headerName: t("Created At"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <DateField value={row.created_at} />;
        },
      },
      {
        field: "companyID",
        headerName: t("Company ID"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.company_id} />;
        },
      },
      {
        field: "reportType",
        headerName: t("Type"),
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.report_type} />;
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <OrderStatus status={row.status} />;
        },
      },
      {
        field: "file_url",
        headerName: t("File"),
        flex: 1,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.file_url} />;
        },
      },
      // {
      //     field: "isActive",
      //     headerName: t("stores.fields.isActive"),
      //     flex: 0.5,
      //     align: "center",
      //     headerAlign: "center",
      //     renderCell: function render({ row }) {
      //         return (
      //             <BooleanField
      //                 svgIconProps={{
      //                     sx: { width: "16px", height: "16px" },
      //                 }}
      //                 value={row.isActive}
      //             />
      //         );
      //     },
      // },
      {
        field: "actions",
        headerName: t("Action"),
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={1}
            label={t("View Details")}
            icon={<EditOutlined />}
            showInMenu
            onClick={() => edit("stores", row.id)}
          />,
          <GridActionsCellItem
            onClick={() => {
              show();
              setShowId(row.id);
            }}
            key={2}
            label={t("Download")}
            icon={<EditOutlined />}
            showInMenu
          />,
        ],
      },
    ],
    [t]
  );

  return (
    <Paper>
      <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          rowHeight={80}
          autoHeight
          density="compact"
          rowsPerPageOptions={[20, 50, 100]}
        />
      </List>
    </Paper>
  );
};
