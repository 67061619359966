import React, { useRef } from "react";
import {
    IResourceComponentsProps,
    useRouterContext,
    useOne
} from "@pankod/refine-core";

import {
    Paper,
    Grid,
    Typography,
    Skeleton,
} from "@pankod/refine-mui";
import { Carousel, Image } from "@pankod/refine-antd";
import { ArrowCircleRightTwoTone, ArrowCircleLeftTwoTone } from "@mui/icons-material";
import { IArticleList } from "interfaces";
import { stripHtml } from 'string-strip-html';
import { decode } from 'html-entities';

export interface IParams {
    id: string;
}

export const FeedDetail: React.FC<IResourceComponentsProps> = () => {

    const { useParams } = useRouterContext();
    const params = useParams() as IParams;
    const slider: any = useRef(null);

    let dataDetail: any = useOne<IArticleList>({
        dataProviderName: "feedprovider",
        resource: "auditor/feed",
        id: params.id,
    });


    const detail = dataDetail.data?.data

    return (
        <Paper style={{ padding: 30, marginBottom: 30 }}>
            <Typography variant='h6' mb={5}><strong>Feed Detail</strong></Typography>
            <Grid container spacing={4}>
                <Grid item lg={3}>
                    <Typography sx={{ fontWeight: 550 }}>Feed Title</Typography>
                </Grid>
                <Grid item lg={9}>
                    {detail ? stripHtml(decode(detail?.title)).result
                        :
                        <Skeleton variant="rectangular" />}
                </Grid>

                <Grid item lg={3}>
                    <Typography sx={{ fontWeight: 550 }}>Feed Type</Typography>
                </Grid>
                <Grid item lg={9}>
                    {detail?.medias ? detail.medias[0].type[0].toUpperCase() + detail.medias[0].type.slice(1)
                        : detail ? '-' :
                            <Skeleton variant="rectangular" />}
                </Grid>

                <Grid item lg={3}>
                    <Typography sx={{ fontWeight: 550 }}>Image/Video</Typography>
                </Grid>
                <Grid item lg={8} style={{ maxWidth: '60vw' }}>
                    {detail?.medias ?
                        <div>
                            {detail.medias.length > 1 && <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    position: 'relative',
                                    top: '20em',
                                    zIndex: 99,
                                    marginRight: 10,
                                    marginLeft: 10,
                                }}>
                                <ArrowCircleLeftTwoTone fontSize='large' sx={{ cursor: 'pointer' }}
                                    htmlColor='whitesmoke' onClick={() => slider.current.prev()} />

                                <ArrowCircleRightTwoTone fontSize='large' sx={{ cursor: 'pointer' }}
                                    htmlColor='whitesmoke' onClick={() => slider.current.next()} />
                            </div>}
                            <Carousel ref={slider}
                                dotPosition='top'>
                                {detail.medias[0].type === 'images' && detail.medias.map((d: any, index: number) => {
                                    return (
                                        <Image src={d.images[0]} alt={`image-${index}`} />
                                    )
                                })}

                                {detail.medias[0].type === 'video' && detail.medias.map((d: any) => {
                                    return (
                                        <div>
                                            <video style={{ maxWidth: '100%', height: 'auto' }} controls>
                                                <source src={d.url} />
                                            </video>
                                        </div>
                                    )
                                })
                                }
                            </Carousel>
                        </div>
                        : detail ? 'No Media'
                            :
                            <Skeleton variant="rectangular" />}
                </Grid>
            </Grid>
        </Paper>
    );
};
