import { useEffect, useState } from "react"

import {
  Stack,
  Grid,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@pankod/refine-mui"

import { AddOutlined, HighlightOff } from "@mui/icons-material"

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material"

import {
  useCreateMany,
  useDataProvider, useDelete,
} from "@pankod/refine-core"
import "react-mde/lib/styles/css/react-mde-all.css"
import { IFlag } from "interfaces"

export interface ResponseAPI {
  success: boolean;
  message: string[];
  data: ResponseData;
}

export interface ResponseData {
  created_at: string;
  updated_at: string;
  name: string;
  value: string;
  company_id: string;
  flag_id: string;
  id: string;
  data: ResponseData;
}

interface AppProps {
  companyId: string;
  flagId: string;
  open: boolean;
  setOpen: any
  setFlagId: any
  onReload: any
  isDelete: boolean
}

interface Payload {
  keyword: string,
  name: string,
  type: string
  company_id?: AppProps["companyId"],
}
interface ItemDropdown {
  id?: string,
  idx?: number,
  value: string,
  name: string,
  flag_id?: string,
  company_id?: string
}

export interface ResponseAPI {
  success: boolean
  message: string[]
  data: ResponseData
}

export const CreateUserSetting = (props: AppProps) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [detail, setDetail] = useState<any>()
  const [payload, setPayload] = useState<Payload>({
    keyword: '',
    name: '',
    type: '',
  })
  const [items, setItem] = useState<ItemDropdown[]>([])
  const { mutate: deleteFlagValue } = useDelete<IFlag>();
  const { mutate: createFLagValue } = useCreateMany<IFlag>();

  const dataProvider = useDataProvider();
  const { create, update, updateMany, getOne } = dataProvider("flagprovider");
  const isEdit = props.flagId !== ''

  useEffect(() => {
    if (isEdit && props.flagId && !props.isDelete) {
      const fetchData = async () => {
        const res = await getOne<IFlag>({
          resource: "flag/" + props.companyId,
          id: props.flagId,
        })
        if (res.data?.id) {
          setDetail(res?.data)
        }
      }
      fetchData()
    }
  }, [isEdit, props.flagId, props.isDelete])

  useEffect(() => {
    if (detail) {
      const newPayload = { ...detail }
      delete newPayload.values
      setPayload(newPayload)
      if (detail.values?.length > 0) {
        let valuesItem = detail.values
        valuesItem.forEach((item: any, i: number) => {
          item.idx = i
          delete item.created_at
          delete item.updated_at
        })
        setItem(valuesItem)
      } else {
        setItem([])
      }
    }
  }, [detail])

  const onRefresh = () => {
    setItem([])
    setPayload({
      keyword: '',
      name: '',
      type: '',
    })
    setLoading(false)
    props.setOpen(false)
    props.onReload()
  }

  const onSubmit = async () => {
    setLoading(true)
    try {

      if (isEdit) {
        const resEdit = await update<ResponseAPI>({
          resource: 'flag',
          id: props.flagId,
          variables: payload,
        })
        if (resEdit.data.success) {
          items.forEach((item) => {
            item.flag_id = props.flagId
            item.company_id = props.companyId
            delete item.idx
          })

          const addItems = items.filter(d => !d.id)
          const delItems = detail?.values?.filter((v: ItemDropdown) => !items.some(s => v.id === s.id))
          const updateItems = items.filter(v => v.id)

          try {
            if (delItems && delItems.length > 0) {
              delItems.map((i: ItemDropdown) => {
                deleteFlagValue({
                  resource: `flag-value/${props.companyId}/${i.id}`,
                  dataProviderName: "flagprovider",
                  id: '',
                })
              })
            }

            if (addItems.length > 0) {
              createFLagValue({
                resource: 'flag-value',
                dataProviderName: 'flagprovider',
                values: addItems,
              })
            }

            updateItems.map(async (i: ItemDropdown) => {
              updateMany && await updateMany<ResponseAPI[]>({
                resource: 'flag-value/' + i.id,
                variables: {
                  name: i.name,
                  value: i.value,
                  company_id: i.company_id,
                  flag_id: i.flag_id,
                },
                ids: []
              })
            })
          } catch (e) {
            console.log("Error update item");
          }
          onRefresh()
        };
      } else {
        payload.company_id = props.companyId
        const resCreate = await create<ResponseAPI>({
          resource: '',
          variables: payload,
        })
        if (resCreate.data.success) {
          items.forEach((item) => {
            item.flag_id = resCreate.data.data.id
            item.company_id = props.companyId
            delete item.idx
          })
          try {
            createFLagValue({
              resource: 'flag-value',
              dataProviderName: 'flagprovider',
              values: items
            })
          } catch (e) {
            console.log("Error create item");
          }

          onRefresh()
        };
      }
    } catch (e) {
      console.log("Error create field", e);
      setLoading(false)
    }
  };

  const handleType = (e: any) => {
    setPayload((prev) => ({
      ...prev,
      type: e.target.value,
    }))
    if (e.target.value === 'dropdown') {
      setItem([
        { idx: items.length, value: '', name: '' },
        { idx: items.length + 1, value: '', name: '' },
      ])
    } else {
      setItem([])
    }
  }

  const onRemoveItem = (item: ItemDropdown) => {
    setItem(items.filter(v => v.idx !== item.idx))
    if (items.length === 1) {
      setPayload((prev) => ({
        ...prev,
        type: '',
      }))
    }
  }

  const lastItem = items[items?.length - 1]
  const isPayload = payload && Object.values(payload).every(x => !!x)
  const isItem = (payload?.type === 'dropdown' && !items?.find(x => !x.value || /^\s*$/.test(x.value))) || payload?.type !== 'dropdown'

  return (
    <Grid item xs={12} lg={12}>
      <Button variant="contained"
        onClick={() => {
          props.setOpen(true)
        }}
        size='small'
        style={{ float: 'right' }}>
        Add New Field
        <AddOutlined />
      </Button>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          {isEdit ? 'Edit' : 'Create'} Field
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} marginTop={3} direction="column">
            <TextField
              onChange={e => setPayload((prev) => ({
                ...prev,
                name: e.target.value,
              }))}
              size="small"
              id="name"
              label="Name of field"
              value={payload?.name}
              required
              variant="outlined"
              fullWidth={true}
            />
            <TextField
              onChange={e => setPayload((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))}
              size="small"
              id="keyword"
              label="Keyword"
              value={payload?.keyword}
              required
              variant="outlined"
              fullWidth={true}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Type of field
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleType}
                value={payload?.type}
                label="Status"
              >
                <MenuItem value={'dropdown'}>Dropdown</MenuItem>
                <MenuItem value={'text'}>Text</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              {items.length > 0 && items.map((item: ItemDropdown) => (
                <Stack spacing={2} marginBottom={3} direction="row"
                  alignItems="center">
                  <TextField
                    onChange={e => {
                      item.name = e.target.value
                      item.value = e.target.value
                      setItem([...items])
                    }}
                    size="small"
                    id="item"
                    label="Item Dropdown"
                    value={item.value}
                    required
                    variant="outlined"
                    fullWidth={true}
                  />
                  <HighlightOff color='error' sx={{ cursor: 'pointer' }}
                    onClick={() => onRemoveItem(item)} />
                </Stack>
              )
              )}
              {payload.type === 'dropdown' &&
                <Grid lg={4}>
                  <Button
                    onClick={() => setItem((prev: ItemDropdown[]) => ([
                      ...prev,
                      { idx: lastItem?.idx && lastItem.idx + 1, name: '', value: '' }
                    ]))}
                    size='small'
                    sx={{ background: '#2F1A33' }}
                    variant='contained'
                  >
                    Add Item
                    <AddOutlined />
                  </Button>
                </Grid>
              }
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setOpen(false)}
            color="secondary"
            size='small'
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit()}
            size='small'
            variant="contained"
            disabled={!(isPayload && isItem) || isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
