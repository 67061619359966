import { useUpdate } from "@pankod/refine-core";
import { ICompanyStatus } from "interfaces";

export const useChangeCompanyStatus = () => {
  const { mutate: updateCompanyStatus } = useUpdate<ICompanyStatus>();

  const onChangeCompanyStatus = (company: ICompanyStatus) => {
    updateCompanyStatus({
      resource: `Company Status`,
      id: company.id,
      values: { company_status: company.status },
    });
  };

  return { onChangeCompanyStatus };
};