import React from "react";
import {
  useTranslate,
  useNavigation,
  IResourceComponentsProps,
  useUpdate,
} from "@pankod/refine-core";
import {
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  DateField,
  TextFieldComponent,
  Paper,
  Link,
  GridToolbar,
  Grid,
  Card
} from "@pankod/refine-mui";
import IframeResizer from "iframe-resizer-react";

import { useOne } from "@pankod/refine-core";
import { IMetabaseEmbed } from "interfaces";

import { EditOutlined } from "@mui/icons-material";

import { IBulkInvite } from "interfaces";

import { OrderStatus } from "components/orderStatus";

export const BulkInviteList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show } = useNavigation();

  // const { show, visible, close } = useModal();

  // const { queryResult, setShowId } = useShow<IBulkInvite>();

  // const { data: showQueryResult } = queryResult;
  // const record = showQueryResult?.data;

  const { dataGridProps } = useDataGrid<IBulkInvite>({
    initialPageSize: 20,
    resource: "research/invitelist/list",
    dataProviderName: "bulkinvite",
  });

  const columns = React.useMemo<GridColumns<IBulkInvite>>(
    () => [
      {
        field: "id",
        align: "center",
        headerName: t("ID"),
      },
      {
        field: "workflow_id",
        headerName: t("WorkflowID"),
        renderCell: function render({ row }) {
          return (
            <Link
              target={"_blank"}
              href={`https://web-temporal.codemi.co.id/namespaces/cdm-prod/workflows?search=basic&query=WorkflowId%3D%22${row.workflow_id}%22`}
            >
              {row.workflow_id}
            </Link>
          );
        },
      },
      // {
      //     field: "title",
      //     headerName: t("stores.fields.title"),
      //     flex: 1,
      //     minWidth: 160,
      // },
      // {
      //     field: "email",
      //     headerName: t("stores.fields.email"),
      //     flex: 2,
      //     minWidth: 300,
      // },
      // {
      //     field: "gsm",
      //     headerName: t("stores.fields.gsm"),
      //     flex: 1,
      //     minWidth: 150,
      // },
      // {
      //     field: "address",
      //     headerName: t("stores.fields.address"),
      //     flex: 2,
      //     minWidth: 300,
      //     renderCell: function render({ row }) {
      //         return <TextFieldComponent value={row.address?.text} />;
      //     },
      // },
      {
        field: "createdAt",
        headerName: t("Created At"),
        renderCell: function render({ row }) {
          return <DateField value={row.created_at} />;
        },
      },
      {
        field: "companyID",
        headerName: t("Company ID"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.company_id} />;
        },
      },
      {
        field: "userTotal",
        headerName: t("Total User"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.user_total} />;
        },
      },
      {
        field: "userSuccess",
        headerName: t("Total Success"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.user_success_total} />;
        },
      },
      {
        field: "userError",
        headerName: t("Total Error"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.user_error_total} />;
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <OrderStatus status={row.status} />;
        },
      },
      // {
      //     field: "isActive",
      //     headerName: t("stores.fields.isActive"),
      //     flex: 0.5,
      //     align: "center",
      //     headerAlign: "center",
      //     renderCell: function render({ row }) {
      //         return (
      //             <BooleanField
      //                 svgIconProps={{
      //                     sx: { width: "16px", height: "16px" },
      //                 }}
      //                 value={row.isActive}
      //             />
      //         );
      //     },
      // },
      {
        field: "actions",
        headerName: t("Action"),
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={1}
            label={t("View Details")}
            icon={<EditOutlined />}
            showInMenu
            onClick={() => show("research/invitelist", row.id)}
          />,
          <GridActionsCellItem
            onClick={() => {
              // show();
              // setShowId(row.id);
            }}
            key={2}
            label={t("Download Failed")}
            icon={<EditOutlined />}
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              onClickRecreate(row);
            }}
            key={2}
            label={t("Recreate Workflow")}
            icon={<EditOutlined />}
            showInMenu
          />,
        ],
      },
    ],
    [t]
  );

  const { mutate: recreateInviteWorkflow } = useUpdate<IBulkInvite>();
  const onClickRecreate = (bulkinvite: IBulkInvite) => {
    recreateInviteWorkflow({
      resource: "bulkinvite/workflow",
      dataProviderName: "researchprovider",
      id: bulkinvite.id,
      values: { recreate: true },
    });
  };

  const metabaseEmbed = useOne<IMetabaseEmbed>({
    dataProviderName: "zordonprovider",
    resource: "dashboard/dashboard/id",
    id: "30",
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Card>
          {!metabaseEmbed.isLoading && (
            <IframeResizer
              // log
              heightCalculationMethod="documentElementScroll"
              // height={"400"}
              width={"100%"}
              // width="800"
              height="100%"
          
              // scrolling={true}
              src={metabaseEmbed.data?.data.url}
              frameBorder="0"
            />
          )}
        </Card>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Paper>
          <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
            <DataGrid
              {...dataGridProps}
              components={{ Toolbar: GridToolbar }}
              columns={columns}
              rowHeight={80}
              autoHeight
              density="compact"
              rowsPerPageOptions={[20, 50, 100]}
            />
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
