type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  return (
    <div className="logo">
      {collapsed ? <h1
        style={{
          // width: "200px",
          padding: "12px 24px",
          fontSize: "2em",
          textAlign: "center",
          color: "#fff"
        }}
      >
        Z
      </h1> :
        <h1
          style={{
            width: "200px",
            padding: "12px 24px",
            fontSize: "2em",
            textAlign: "center",
            color: "#fff"
          }}
        >
          Zordon
        </h1>}
    </div>
  );
};
