import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function CardData({ header = "Header", color, data }: any) {
  return (
    <Card sx={{ minWidth: 150, minHeight: 100 }}>
      <div
        style={{
          background: `${color}`,
          padding: "14px",
          color: "white",
          fontWeight: "700",
        }}
      >
        <span>{header}</span>
      </div>
      <CardContent
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <span>Total</span>
        <span style={{ fontSize: "24px" }}>{data}</span>
      </CardContent>
    </Card>
  );
}
