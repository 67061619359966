import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";
import {
  DataProvider,
  HttpError,
} from "@pankod/refine-core";

import { CrudSorting, CrudFilters } from "@pankod/refine-core/dist/interfaces";

const axiosInstance = axios.create();
var resourceCache: any = {};

export interface INewCompany {
  name: string;
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];

    sort.map((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });

    return {
      _sort,
      _order,
    };
  }

  return;
};

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};
  if (filters) {
    filters.map((filter) => {
      if (
        filter.operator !== "or" &&
        filter.operator !== "and" &&
        "field" in filter
      ) {
        const { field, value } = filter;

        queryFilters[`${field}`] = value;
      }
    }
    );
  }

  return queryFilters;
};

export const FlagDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
  responseKey: string = "data"
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort }) => {
    const url = `${apiUrl}/${resource}`;

    // pagination
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const queryFilters = generateFilter(filters);

    const query: {
      _sort?: string;
      _order?: string;
      page: number;
      per_page: number;
    } = {
      page: current,
      per_page: pageSize,
    };

    const { data } = await httpClient.get(
      `${url}&${stringify(query)}&${stringify(queryFilters)}`
    );
    
    resourceCache[resource] = data["data"][responseKey];

    return {
      data: data["data"][responseKey],
      total: data["data"]["pagination"]["total_data"],
    };
  },

  create: async ({ resource, variables }: any) => {
    const url = `${apiUrl}/flag`;
    const { data } = await httpClient.post(
      url, variables);
    return {
      data,
    };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (variable) => {
        const { data } = await httpClient.post(
          `${apiUrl}/${resource}`,
          variable
        );
        return data;
      })
    );

    return { data: response };
  },

  update: async ({ resource, id, variables }) => {

    const resEnableConfig = await httpClient.put(
      `${apiUrl}/${resource}/${id}`,
      variables
    );

    return {
      data: resEnableConfig["data"],
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const { data } = await httpClient.put(
      `${apiUrl}/${resource}`,
      variables
    );
    return data;
  },

  getOne: async ({ resource, id }) => {

    const url = `${resource}`;

    const getArticle = await httpClient.get(`${apiUrl}/${url}/${id}`)
    const detailArticle = getArticle.data.data

    return {
      data: detailArticle
    }
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`
    );

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.delete(url);

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
        return data;
      })
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default FlagDataProvider;
