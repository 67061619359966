import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  HttpError,
  useDelete,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  TextFieldComponent,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@pankod/refine-mui";
import { useRouterContext } from "@pankod/refine-core";
import { EditOutlined, DeleteOutlined, InfoOutlined } from "@mui/icons-material";

import { IFlag } from "interfaces";
import { CreateUserSetting } from './create_user'
export interface IParams {
  id: string;
}

export const UserSettingList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { useParams } = useRouterContext();
  const params = useParams() as IParams;
  const [open, setOpen] = React.useState<boolean>(false)
  const [openCreate, setOpenCreate] = React.useState<boolean>(false)
  const [flagId, setFlagId] = React.useState<string>('')
  const [isDelete, setIsDelete] = React.useState<boolean>(false)
  const { mutate: deleteFlag, isLoading, isSuccess, isError } = useDelete<IFlag>();

  const { dataGridProps, setPageSize, pageSize } = useDataGrid<
    IFlag,
    HttpError
  >({
    dataProviderName: "flagprovider",
    resource: `flag?company_id=${params.id}`,
  })

  const onDelete = () => {
    deleteFlag({
      resource: `flag/${params.id}/${flagId}`,
      dataProviderName: "flagprovider",
      id: '',
    })
  }

  const onReload = () => {
    setPageSize(pageSize > 24 ? 24 : 25)
    setFlagId('')
  }

  React.useEffect(() => {
    if (isSuccess === true) {
      setOpen(false);
      onReload()
      setIsDelete(false)
    }
    if (isError) {
      setOpen(false);
      setFlagId('')
      setIsDelete(false)
    }
  }, [isSuccess, isError]);

  const columns = React.useMemo<GridColumns<IFlag>>(
    () => [
      {
        field: "name",
        headerName: t("Name"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.name} />;
        },
      },
      {
        field: "keyword",
        headerName: t("Keyword"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.keyword} />;
        },
      },
      {
        field: "type",
        headerName: t("Type"),
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.type} />;
        },
      },
      {
        field: "values",
        headerName: t("Value"),
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.values?.length > 0 ? `${row.values?.length} item` : '-'} />;
        },
      },
      {
        field: "actions",
        headerName: t("Action"),
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={1}
            label={t("Edit")}
            icon={<EditOutlined />}
            showInMenu
            onClick={() => {
              setOpenCreate(true)
              setFlagId(row.id)
            }}
          />,
          <GridActionsCellItem
            key={1}
            label={t("Delete")}
            icon={<DeleteOutlined sx={{ color: 'red' }} />}
            showInMenu
            sx={{ color: 'red' }}
            onClick={() => {
              setOpen(true)
              setFlagId(row.id)
              setIsDelete(true)
            }
            }
          />,
        ],
      },
    ],
    [t]
  );

  return (
    <Grid container spacing={2}>
      <CreateUserSetting
        companyId={params.id}
        flagId={flagId}
        setOpen={setOpenCreate}
        open={openCreate}
        setFlagId={setFlagId}
        onReload={onReload}
        isDelete={isDelete} />
      <Grid item xs={12} lg={12}>
        <Paper>
          <DataGrid
            {...dataGridProps}
            columns={columns}
            rowHeight={80}
            autoHeight
            density="compact"
            filterModel={undefined}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </Paper>
      </Grid>
      <Dialog
        open={open}
        onBackdropClick={() => setIsDelete(false)}
        onClose={() => setOpen(false)}
      >
        <DialogContent sx={{ textAlign: 'center', marginTop: 3 }}>
          <InfoOutlined sx={{ color: 'red', fontSize: 90, marginBottom: 2 }} />
          <Typography>Are you sure you want to delete Field?</Typography>
        </DialogContent>
        <DialogActions sx={{ marginY: 1 }}>
          <Button
            onClick={() => {
              setOpen(false)
              setIsDelete(false)
            }}
            color="secondary"
            size='small'
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onDelete()}
            size='small'
            variant="contained"
            color='error'
            disabled={isLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
