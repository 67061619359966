import React from "react";
import {
  useTranslate,
  useNavigation,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  DateField,
  TextFieldComponent,
  Paper,
} from "@pankod/refine-mui";
import { IBulkInviteUser } from "interfaces";
import { useRouterContext } from "@pankod/refine-core";

export interface IParams {
  id: string;
}

export const BulkInviteDetail: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { useParams } = useRouterContext();
  const params = useParams() as IParams;

  const { dataGridProps } = useDataGrid<IBulkInviteUser>({
    initialPageSize: 20,
    resource: "research/invitelist/detail/" + params.id,
    dataProviderName: "bulkinvite",
  });

  const columns = React.useMemo<GridColumns<IBulkInviteUser>>(
    () => [
      {
        field: "id",
        align: "center",
        minWidth: 150,
        headerName: t("ID"),
      },

      {
        field: "companyID",
        headerName: t("Company ID"),
        minWidth: 150,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.company_id} />;
        },
      },
      {
        field: "createdAt",
        headerName: t("Created At"),
        minWidth: 100,
        renderCell: function render({ row }) {
          return <DateField value={row.created_at} />;
        },
      },
      {
        field: "name",
        headerName: t("Name"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.name} />;
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.status} />;
        },
      },
      {
        field: "duplicate",
        headerName: t("Duplicate"),
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.duplicate} />;
        },
      },
      {
        field: "userError",
        headerName: t("Error"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.general_error} />;
        },
      },
    ],
    [t]
  );
  return (
    <Paper>
      <List
        title={"Bulk Invite Details"}
        cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
      >
        {/* <h3>Success</h3> */}
        <DataGrid
          {...dataGridProps}
          columns={columns}
          rowHeight={80}
          autoHeight
          density="compact"
          rowsPerPageOptions={[20, 50, 100]}
        />
        {/* <h3>Failed</h3>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          rowHeight={80}
          autoHeight
          density="compact"
          rowsPerPageOptions={[20, 50, 100]}
        /> */}
      </List>
    </Paper>
  );
};
