import React from "react";
import {
  useTranslate,
  useNavigation,
  IResourceComponentsProps,
  HttpError,
  getDefaultFilter,
  CrudFilters,
} from "@pankod/refine-core";
import {
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  DateField,
  TextFieldComponent,
  Paper,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  Button,
  InputAdornment,
  SelectChangeEvent,
} from "@pankod/refine-mui";
import { ModalComponent } from "./components/addcreditmodal"
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { EditOutlined } from "@mui/icons-material";
import {  ICompany } from "interfaces";


import { useDetachDomain } from "./hooks/usedetachdomain";
import { useAttachDomain } from "./hooks/useattachdomain";
import { useChangeCompanyStatus } from "./hooks/status";

export interface IUserFilterVariables {
  q: string;
  created_from: string;
}


export const CompanyListMUI: React.FC<IResourceComponentsProps> = () => {
  const { edit } = useNavigation();
  const t = useTranslate();
  const { dataGridProps, search, filters } = useDataGrid<
    ICompany,
    HttpError,
    IUserFilterVariables
  >({
    initialPageSize: 20,
    dataProviderName: "default",
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { created_from, q } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });
      filters.push({
        field: "create_from",
        operator: "eq",
        value: created_from,
      });
      return filters;
    },
  });

  const { register, handleSubmit, control } = useForm<
    ICompany,
    HttpError,
    IUserFilterVariables
  >({
    defaultValues: {
      q: getDefaultFilter("q", filters, "eq"),
    },
  });



  const { onClickDetachDomain } = useDetachDomain();
  const { attachDomainMutation } = useAttachDomain();
  const { onChangeCompanyStatus } = useChangeCompanyStatus();

  // Add the following state variables inside the CompanyListMUI component
  const [open, setOpen] = React.useState<boolean>(false);
  const [company, setCompany] = React.useState<ICompany | null>(null);

  // Add the following functions inside the CompanyListMUI component
  const handleClickOpen = (title: string, company: ICompany) => {
    setCompany(company)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = React.useMemo<GridColumns<ICompany>>(
    () => [
      {
        field: "id",
        align: "center",
        headerName: t("ID"),
      },
      {
        field: "name",
        headerName: t("Company"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.name} />;
        },
      },
      {
        field: "domain",
        headerName: t("Domain"),
        flex: 1,
        minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.domain} />;
        },
      },
      {
        field: "create_from",
        headerName: t("Type"),
        // flex: 1,
        // minWidth: 100,
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.create_from} />;
        },
      },
      {
        field: "custome_type",
        headerName: t("Customer Type"),
        // flex: 1,
        // minWidth: 50,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.customer_type} />;
        },
      },
      {
        field: "user_total",
        headerName: t("Total User"),
        // flex: 1,
        // minWidth: 50,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.user_total} />;
        },
      },
      {
        field: "company_status",
        headerName: t("Status"),
        // flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            <Select
              size={"small"}
              fullWidth
              placeholder="Company Status"
              defaultValue={row.company_status}
              onChange={(event: SelectChangeEvent<string>) => {
                onChangeCompanyStatus({ id: row.id, status: event.target.value as "trial" | "active" | "request-delete" })
              }}
            >
              <MenuItem value={"trial"}>Trial</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"request-delete"}>Request Delete</MenuItem>
            </Select>
          );
        },
      },
      {
        field: "created_at",
        headerName: t("Created At"),
        // flex: 1,
        // minWidth: 50,
        renderCell: function render({ row }) {
          return <DateField value={row.created_at} />;
        },
      },
      {
        field: "expired_at",
        headerName: t("Expired At"),
        // flex: 1,
        // minWidth: 50,
        renderCell: function render({ row }) {
          return (
            <>
              {
                row.expired_at !== null ? (
                  <DateField value={row.expired_at} />
                ) : (
                  <span>-</span>
                )
              }
            </>
          )
        },
      },
      {
        field: "actions",
        headerName: t("Action"),
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={1}
            label={t("Edit")}
            icon={<EditOutlined />}
            showInMenu
            onClick={() => edit("company", row.id)}
          />,
          <GridActionsCellItem
            onClick={() => {
              onClickDetachDomain(row);
            }}
            key={2}
            label={t("Detach Cloudflare Domain")}
            icon={<EditOutlined />}
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              attachDomainMutation(row);
            }}
            key={2}
            label={t("Attach Cloudflare Domain")}
            icon={<EditOutlined />}
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              // Add your add credit action logic here
              handleClickOpen("Add Credit", row)
            }}
            key={3}
            label={t("Add Credit")}
            icon={<EditOutlined />}
            showInMenu
          />,
        ],
      },
    ],
    // eslint-disable-next-line 
    [t]
  );

  return (
    <Grid container spacing={2}>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        company={company!}
      />

      <Grid item xs={12} lg={12}>
        <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
          <CardHeader title={t("Filters")} />
          <CardContent sx={{ pt: 0 }}>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column" }}
              autoComplete="off"
              onSubmit={handleSubmit(search)}
            >
              <TextField
                {...register("q")}
                label={t("Company Name")}
                placeholder={t("Codemi")}
                margin="normal"
                fullWidth
                autoFocus
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                control={control}
                name="created_from"
                render={({ field }) => (
                  <FormControl margin="normal" size="small">
                    <InputLabel id="created_from">Created From</InputLabel>
                    <Select
                      {...field}
                      labelId="created_from"
                      label={"Created From"}
                      sx={{
                        marginBottom: "0.5em"
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="codemi">Codemi</MenuItem>
                      <MenuItem value="skillomo">Skillomo</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Button type="submit" variant="outlined">
                {t("Search")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Paper>
          <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
            <DataGrid
              {...dataGridProps}
              columns={columns}
              rowHeight={80}
              autoHeight
              density="compact"
              filterModel={undefined}
              rowsPerPageOptions={[20, 50, 100]}
            />
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
