import React from "react";
import {
  IResourceComponentsProps,
  useOne,
  useNavigation,
  getDefaultFilter,
  useTranslate,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";

import { IArticleCount, IArticleList, IDataCategories } from "interfaces";
import {
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  DateField,
  TextFieldComponent,
  Link,
  Paper,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormControl,
  Box,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@pankod/refine-mui";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CardData from "components/course-card";
import PopOverComponent from "components/popover";
import { Controller, useForm } from "@pankod/refine-react-hook-form";

const colorStatusMap: { [key: string]: any } = {
  need_approval: "#F9B300",
  inactive: "#929292",
  active: "#38A169",
  scheduled: "#0089F9",
  rejected: "#FF3C35",
};

const labelStatusMap: { [key: string]: any } = {
  need_approval: "NEED APPROVAL",
  inactive: "INACTIVE",
  active: "ACTIVE",
  scheduled: "SCHEDULED",
  rejected: "REJECTED",
};

export interface IUserFilterVariables {
  title: string;
  status: string;
  categories: string;
}

export const ArticleList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show } = useNavigation();
  const { dataGridProps, search, filters } = useDataGrid<
    IArticleList,
    HttpError,
    IUserFilterVariables
  >({
    initialPageSize: 20,
    resource: "auditor/blog",
    dataProviderName: "articleprovider",
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { status, title, categories } = params;
      filters.push({
        field: "title",
        operator: "eq",
        value: title,
      });
      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });
      filters.push({
        field: "categories",
        operator: "eq",
        value: categories,
      });
      return filters;
    },
  });

  const dataCount = useOne<IArticleCount>({
    dataProviderName: "articleprovider",
    resource: "auditor/blog/count",
    id: "",
  });

  const { register, handleSubmit, control } = useForm<
    IArticleList,
    HttpError,
    IUserFilterVariables
  >({
    defaultValues: {
      title: getDefaultFilter("title", filters),
    },
  });

  const columns = React.useMemo<GridColumns<IArticleList>>(
    () => [
      {
        field: "title",
        headerName: t("Title"),
        minWidth: 300,
        headerAlign: "center",
        align: "left",
        renderCell: function render({ row }) {
          return <Link underline='none' href='#' onClick={() => show("article/articlelist", row.id)}>{row.title || "-"}</Link>;
        },
      },
      {
        field: "category",
        headerName: t("Category"),
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          const categoriesModified = row?.categories?.map((v) => v?.name).join();
          return <TextFieldComponent value={categoriesModified || "-"} />;
        },
      },
      {
        field: "provider",
        headerName: t("Provider"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.company_name || "-"} />;
        },
      },
      {
        field: "dateFlagged",
        headerName: t("Date Flagged"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            row?.submission_audit_at ? <DateField format="DD MMM YYYY" value={row.submission_audit_at} /> : "-"
          );
        },
      },
      {
        field: "publishDate",
        headerName: t("Publish Date"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            <div>
              {row.approve_audit_at ?
                <DateField format="DD MMM YYYY" value={row?.approve_audit_at} />
                :
                <PopOverComponent
                  row={row}
                  icon
                  fontCol="#004FB3"
                  customButton={false}
                  spanName="Set Publish Date"
                  variant="date_picker"
                  flag="date"
                  keyApi='blog'
                />
              }
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            <>
              <PopOverComponent
                row={row}
                customButton={false}
                fontCol="white"
                colorBgSpan={colorStatusMap[row.status_audit]}
                spanName={labelStatusMap[row.status_audit]}
                variant="approval"
                flag={row.status_audit}
                keyApi='blog'
              />
            </>
          );
        },
      },
      {
        field: "approver",
        headerName: t("Approver"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row?.approver?.name || '-'} />;
        },
      },
      {
        field: "activeCompany",
        headerName: t("Active Company"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.active_company || "-"} />;
        },
      },
    ],
    [t]
  );

  return (
    <Grid container spacing={2}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        columns={17}
      >
        <Grid item xs={4}>
          <CardData
            header="Need Approval"
            color="#F9B300"
            data={dataCount.data?.data?.total_need_approval}
          />
        </Grid>
        <Grid item xs={4}>
          <CardData
            header="Scheduled"
            color="#0089F9"
            data={dataCount.data?.data?.total_scheduled}
          />
        </Grid>
        <Grid item xs={4}>
          <CardData
            header="Active Article"
            color="#3AB549"
            data={dataCount.data?.data?.total_active}
          />
        </Grid>
        <Grid item xs={4}>
          <CardData
            header="Inactive Article"
            color="#929292"
            data={dataCount.data?.data?.total_inactive}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
          <CardHeader title={t("Filters")} />
          <CardContent sx={{ pt: 0 }}>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column" }}
              autoComplete="off"
              onSubmit={handleSubmit(search)}
            >
              <TextField
                {...register("title")}
                label={t("Title")}
                placeholder={t("Article 1..")}
                margin="normal"
                fullWidth
                autoFocus
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <Controller
                control={control}
                name="categories"
                render={({ field }) => (
                  <FormControl margin="normal" size="small">
                    <InputLabel id="categories">Categories</InputLabel>
                    <Select
                      {...field}
                      labelId="categories"
                      label={"Category"}
                      sx={{
                        marginBottom: "0.5em"
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {dataCategories && dataCategories.length > 0 && dataCategories.map((d: any) => {
                        return (
                          <MenuItem value={d.id}>{d.name}</MenuItem>
                        )
                      })}

                    </Select>
                  </FormControl>
                )}
              /> */}
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <FormControl margin="normal" size="small">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      {...field}
                      labelId="status"
                      label={"Status"}
                      sx={{
                        marginBottom: "0.5em"
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="scheduled">Scheduled</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                      <MenuItem value="need_approval">Need Approval</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Button type="submit" variant="outlined">
                {t("Search")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Paper>
          <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
            <DataGrid
              {...dataGridProps}
              columns={columns}
              rowHeight={80}
              autoHeight
              filterModel={undefined}
              density="standard"
              rowsPerPageOptions={[20, 50, 100]}
            />
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
