import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  LinearProgress,
} from "@mui/material";


import { ICompany } from "interfaces";
import { GridExpandMoreIcon } from "@pankod/refine-mui";

import { useAddCredit } from "../hooks/addcredit"

interface IModalComponentProps {
  open: boolean;
  handleClose: () => void;
  company: ICompany | null;
}

export const ModalComponent: React.FC<IModalComponentProps> = ({
  open,
  handleClose,
  company,
}) => {
  const [licenseAmount, setLicenseAmount] = React.useState<number | null>(
    null
  );
  const [seatAmount, setSeatAmount] = React.useState<number | null>(null);

  const { handleAddCredit, isLoading, isSuccess } = useAddCredit();

  useEffect(() => {
    if (isSuccess === true) {
      handleClose();
      setSeatAmount(null);
      setLicenseAmount(null);
    }
  }, [isSuccess]);


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      {isLoading && <LinearProgress style={{ height: "5x" }} />}
      <DialogTitle>
        Add Credit to: <strong>{company?.name}</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          This action will increase the company <strong>license</strong>, and{" "}
          <strong>seat</strong> amounts to the entered values.
        </DialogContentText>
        <Accordion>
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>License Credit</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="License Amount"
              value={licenseAmount}
              onChange={(e) => setLicenseAmount(Number(e.target.value))}
              type="number" // added type="number"
              fullWidth
              margin="normal"
              disabled={isLoading}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Seat Credit</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Seat Amount"
              value={seatAmount}
              onChange={(e) => setSeatAmount(Number(e.target.value))}
              type="number" // added type="number"
              fullWidth
              margin="normal"
              disabled={isLoading}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={() => handleAddCredit(company!, seatAmount, licenseAmount)}
          color="primary"
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};