import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { useCustomMutation } from "@pankod/refine-core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { ICourseBulk } from "interfaces";

var API_URL = "";

if (window.location.hostname.includes("zordon.codemi.co.id") === true) {
  API_URL = "https://api-id.codemi.co.id";
} else if (window.location.hostname.includes("zordon.pawonmburi.com")) {
  API_URL = "https://api.staging.pawonmburi.com";
} else if (window.location.hostname.includes("zordon.production.lvh.me")) {
  API_URL = "https://api-id.codemi.co.id";
} else if (window.location.hostname.includes("zordon.staging.lvh.me")) {
  API_URL = "https://api.staging.pawonmburi.com";
} else {
  API_URL = "https://api.staging.pawonmburi.com";
}

function StaticDatePickerComponent({ keyApi, courseId, handleCloseModal }: any) {
  const now = dayjs();
  const [valueDate, setValueDate] = React.useState<Dayjs | null>(now);

  const { mutate } = useCustomMutation<ICourseBulk>();

  const handleSubmit = () => {
    const payloadCourse = {
      course_id: new Array(courseId),
      name: "publish_date",
      value: dayjs(valueDate).startOf("h").format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    const payloadArticle = {
      status: 'active',
      approve_audit_at: dayjs(valueDate).startOf("h").format("YYYY-MM-DD"),
    };
    const payload = keyApi === 'course' ? payloadCourse : payloadArticle
    const url = keyApi === 'course' ? `${API_URL}/api/v1/auditor/course/bulk/update` :
      API_URL + '/api/v1/auditor/' + keyApi + '/' + courseId

    mutate(
      {
        url: url,
        method: "post",
        values: payload,
      },
      {
        onSuccess(data: any, variables: any, context: any) {
          if (data?.data?.success) {
            window.location.reload();
            handleCloseModal();
          }
        },
      }
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          disablePast
          openTo="day"
          value={valueDate}
          onChange={(newValue: any) => {
            setValueDate(newValue);
          }}
          renderInput={(params: any) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "20px",
          gap: "20px",
        }}
      >
        <Button
          variant="text"
          style={{ color: "#2F1A33" }}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ background: "#2F1A33" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

function ApprovalComponent({ keyApi, courseId, handleCloseModal }: any) {
  const { mutate } = useCustomMutation();
  const handleSetState = (event: any) => {
    const { id } = event.target;

    const url = keyApi === 'course' ? `${API_URL}/api/v1/auditor/course/approval/${courseId}/${id}` :
      `${API_URL}/api/v1/auditor/${keyApi}/${courseId}`
    const values = keyApi === 'course' ? {} : { status: id }

    mutate(
      {
        url: url,
        method: "post",
        values: values,
      },
      {
        onSuccess(data: any, variables: any, context: any) {
          if (data?.data?.success) {
            window.location.reload();
            handleCloseModal();
          }
        },
      }
    );
  };

  return (
    <div
      style={{
        textAlign: "center",
        width: "296px",
        height: "287px",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        justifyContent: "center",
        gap: "30px",
        fontWeight: "700",
      }}
    >
      <span>Give approval to this course ?</span>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Button
          variant="contained"
          color="success"
          id={keyApi === 'course' ? "approve" : "active"}
          onClick={(e) => handleSetState(e)}
        >
          Approve
        </Button>
        <Button
          id={keyApi === 'course' ? "disapprove" : "rejected"}
          variant="contained"
          color="error"
          onClick={(e) => handleSetState(e)}
        >
          Dissaprove
        </Button>
      </div>
      <Button variant="outlined" color="success" onClick={handleCloseModal}>
        Cancel
      </Button>
    </div>
  );
}

export default function PopOverComponent({
  customButton = false,
  spanName = "Span Name",
  colorBgSpan = "",
  fontCol = "#626262",
  variant = "date_picker",
  row,
  icon = false,
  flag,
  keyApi,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const componentRender: { [key: string]: any } = {
    date_picker: (
      <StaticDatePickerComponent
        keyApi={keyApi}
        courseId={keyApi === 'course' ? row.course_id : row.content_provider_id}
        handleCloseModal={handleClose}
      />
    ),
    approval: (
      <ApprovalComponent
        keyApi={keyApi}
        courseId={keyApi === 'course' ? row.course_id : row.content_provider_id}
        handleCloseModal={handleClose}
      />
    ),
  };

  return (
    <div>
      {customButton ? (
        <Button aria-describedby={id} variant="contained" onClick={handleClick}>
          Open Popover
        </Button>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && (
            <AddCircleOutlineIcon style={{ color: "#004FB3", width: "16px" }} />
          )}
          <span
            style={{
              background: `${colorBgSpan}`,
              borderRadius: "6px",
              padding: "4px",
              color: `${fontCol}`,
              cursor: "pointer",
            }}
            onClick={
              flag === "date"
                ? handleClick
                : flag !== "need_approval"
                  ? () => { }
                  : handleClick
            }
          >
            {spanName}
          </span>
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {componentRender[variant]}
      </Popover>
    </div>
  );
}
