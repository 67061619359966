import React from "react";
import {
  IResourceComponentsProps,
  useOne,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";

import { ICourseCount, ICourseList } from "interfaces";
import {
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  DateField,
  TextFieldComponent,
  Link,
  Paper,
  GridToolbar,
  Grid,
} from "@pankod/refine-mui";
import CardData from "components/course-card";
import PopOverComponent from "components/popover";

const colorStatusMap: { [key: string]: any } = {
  need_approval: "#F9B300",
  inactive: "#929292",
  active: "#38A169",
  scheduled: "#0089F9",
  rejected: "#FF3C35",
};

const labelStatusMap: { [key: string]: any } = {
  need_approval: "NEED APPROVAL",
  inactive: "INACTIVE",
  active: "ACTIVE",
  scheduled: "SCHEDULED",
  rejected: "REJECTED",
};

export const CourseList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show } = useNavigation();
  const { dataGridProps } = useDataGrid<ICourseList>({
    initialPageSize: 20,
    resource: "auditor/course",
    dataProviderName: "courseprovider",
  });

  const dataCount = useOne<ICourseCount>({
    dataProviderName: "courseprovider",
    resource: "auditor/course/count",
    id: "",
  });

  const columns = React.useMemo<GridColumns<ICourseList>>(
    () => [
      {
        field: "title",
        headerName: t("Title"),
        minWidth: 200,
        headerAlign: "center",
        align: "left",
        renderCell: function render({ row }) {
          return <Link underline='none' href='#' onClick={() => show("course/courselist", row.course_id)}>{row.title || "-"}</Link>;
        },
      },
      {
        field: "category",
        headerName: t("Category"),
        minWidth: 200,
        headerAlign: "center",
        align: "left",
        renderCell: function render({ row }) {
          const categoriesModified = row?.course_categories?.map((v) => v?.title).join();
          return <TextFieldComponent value={categoriesModified || "-"} />;
        },
      },
      {
        field: "provider",
        headerName: t("Provider"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.provider_name || "-"} />;
        },
      },
      {
        field: "dateFlagged",
        headerName: t("Date Flagged"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            <DateField format="DD MMM YYYY" value={row?.date_flagged || "-"} />
          );
        },
      },
      {
        field: "publishDate",
        headerName: t("Publish Date"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            <div>
              {row.publish_date !== null ? (
                <DateField format="DD MMM YYYY" value={row?.publish_date} />
              ) : (
                <PopOverComponent
                  row={row}
                  icon
                  fontCol="#004FB3"
                  customButton={false}
                  spanName="Set Publish Date"
                  variant="date_picker"
                  flag="date"
                  keyApi='course'
                />
              )}
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return (
            <>
              <PopOverComponent
                row={row}
                customButton={false}
                fontCol="white"
                colorBgSpan={colorStatusMap[row.status]}
                spanName={labelStatusMap[row.status]}
                variant="approval"
                flag={row.status}
                keyApi='course'
              />
            </>
          );
        },
      },
      {
        field: "approver",
        headerName: t("Approver"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row?.approver_name || "-"} />;
        },
      },
      {
        field: "activeCompany",
        headerName: t("Active Company"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row?.total_active_company || "-"} />;
        },
      },
    ],
    [t]
  );

  return (
    <Grid container spacing={2}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        columns={17}
      >
        <Grid item xs={4}>
          <CardData
            header="Need Approval"
            color="#F9B300"
            data={dataCount.data?.data?.total_need_approval}
          />
        </Grid>
        <Grid item xs={4}>
          <CardData
            header="Scheduled"
            color="#0089F9"
            data={dataCount.data?.data?.total_scheduled}
          />
        </Grid>
        <Grid item xs={4}>
          <CardData
            header="Active Course"
            color="#3AB549"
            data={dataCount.data?.data?.total_active}
          />
        </Grid>
        <Grid item xs={4}>
          <CardData
            header="Inactive Course"
            color="#929292"
            data={dataCount.data?.data?.total_inactive}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Paper>
          <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
            <DataGrid
              {...dataGridProps}
              components={{ Toolbar: GridToolbar }}
              columns={columns}
              rowHeight={80}
              autoHeight
              density="standard"
              rowsPerPageOptions={[20, 50, 100]}
            />
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
