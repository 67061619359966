
import { useUpdate } from "@pankod/refine-core";
import { IAddCredit, ICompany } from "interfaces";

export const useAddCredit = () => {
  const { mutate: addCredit, isLoading, isSuccess } = useUpdate<IAddCredit>();

  const handleAddCredit = (company: ICompany | null, seatAmount: number | null, licenseAmount: number | null) => {
    const company_credits:any[] = []
    
    if (seatAmount) {
      company_credits.push(
        {
          credit_type: "seat",
          amount: seatAmount,
        })
    }
    if (licenseAmount) {
      company_credits.push(
        {
          credit_type: "license",
          amount: licenseAmount,
        })
    }
    
    return addCredit({
        resource: "company-balance/topup",
        dataProviderName: "codemiapiproviderv1",
        id: company?.id!,
        values: {
          company_id: company?.id,
          details: company_credits,
        },
      });
    };

  return { handleAddCredit, isLoading, isSuccess };
};

