import { useEffect, useState } from "react";

import {
  Stack,
  Edit,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Checkbox,
  Typography,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  GridExpandMoreIcon,
  Box,
} from "@pankod/refine-mui";

import { useForm } from "@pankod/refine-react-hook-form";

import {
  IResourceComponentsProps,
  HttpError,
  useCustom,
} from "@pankod/refine-core";
import "react-mde/lib/styles/css/react-mde-all.css";

import { ICompany, INewLearninghub, IConfigLearninghub } from "interfaces";

import { UserSettingList } from "./list_user";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

type staffKeys = keyof ICompany;
const config_list: staffKeys[] = [
  "enable_timeline",
  "enable_lms",
  "enable_is_teacher_collaborative",
  "enable_gamification",
  "enable_customer_prerequisite",
  "enable_reset_password",
  "enable_course_catalog",
  "enable_self_registration",
  "enable_discussion",
  "enable_blog",
  "enable_public_blog",
  "enable_reward",
  "enable_email_template",
  "enable_company_info",
  "enable_billing",
  "enable_change_password",
  "enable_public_newsfeed",
  "enable_onboarding",
  "enable_user_profile",
  "enable_user_profile_info",
  "enable_learning_calendar",
  "enable_highest_course_score",
  "enable_learning_plan_menu",
  "enable_category_menu",
  "enable_class_menu",
  "enable_e_learning",
  "enable_webinar",
  "enable_coaching",
  "enable_mentoring",
  "enable_counselor",
  "enable_show_all_category_menu",
  "enable_default_show_course",
  "enable_default_show_learning_path",
  "enable_learning_wallet",
  "enable_learning_hour",
  "enable_select_company",
  "enable_learning_diary",
  "enable_skillomo",
  "enable_learning_path_on_my_course",
  "enable_help_center",
  "enable_survey_360",
  "enable_leaderboard_by_group",
  "enable_change_password_first_login",
  "enable_my_completed_lesson_catalog",
  "enable_my_team",
  "enable_qualification",
  "enable_blogpost_comment",
  "enable_enrolled_course_in_latest",
  "enable_course_announcement",
  "enable_expired_course_in_latest",
  "enable_expired_course_in_recommendation",
  "enable_enrolled_course_in_catalog",
  "enable_expired_course_in_catalog",
  "enable_enrolled_course_in_search",
  "enable_expired_course_in_search",
  "enable_paid_course",
  "enable_business_card",
  "enable_learning_request",
  "enable_mission",
  "enable_expired_user_notification",
  "enable_live_support",
  "enable_popup_update",
  "enable_mandatory_update",
  "enable_download_course_material",
  "enable_show_learning_request_claim_category",
  "enable_show_user_profile_level",
  "enable_show_lesson_library",
  "enable_change_language",
  "enable_open_class",
  "enable_offline_material",
  // "enable_codemi_login",
  "enable_external_login",
  // "enable_azure_login",
  "enable_biometric",
  "enable_inspigo",
  "enable_learning_onboarding",
  "enable_notif_article",
  "enable_notif_feed",
  "enable_home_open_class",
  "enable_notif_open_class",
  "enable_point_category_article",
  "enable_screenshot",
  "enable_marketplace_access",
  "enable_challenge",
  "enable_share_to_feed",
  "enable_default_autofinish_session",
  "enable_chat_and_co",
  "enable_point_share_article",
  "enable_password_expired",
];

interface ExtendedICompany extends ICompany {
  learninghub: INewLearninghub & {
    status?: string;
    domain?: string;
    config: IConfigLearninghub;
  };
}

export const EditCompany: React.FC<IResourceComponentsProps> = () => {
  const {
    register,
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    setValue,
    getValues,
    watch,
  } = useForm<ExtendedICompany, HttpError, ExtendedICompany>({
    refineCoreProps: {
      action: "edit",
      redirect: false,
    },
  });

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [configLearninghub, setLerninghubConfig] = useState<any>(undefined);
  const [learninghubStatus, setLearninghubStatus] = useState<string>("inactive");

  const handleStartDateChange = (value: string | undefined) => {
    if (typeof value === "string") {
      const parsedDate = new Date(value);
      return parsedDate;
    }
  };

  const transformDate = handleStartDateChange(
    queryResult?.data?.data["expired_at"]
  );

  const planData: any = useCustom({
    url: `company/plan`,
    method: "get",
    dataProviderName: "default",
  });
  const planList = planData?.data?.data.data;

  const cncData: any = useCustom({
    url: `cnc/plan`,
    method: "get",
    dataProviderName: "default",
  });
  const cncList = cncData?.data?.data.data;

  const learninghubData: any = useCustom({
    url: `learninghub/company_id/${queryResult?.data?.data.id}`,
    method: "get",
    dataProviderName: "default",
  });
  const learninghubList = learninghubData?.data?.data?.data;

  useEffect(() => {
    if (!startDate) {
      setStartDate(transformDate);
    }
  }, [startDate]);

  const customerType = watch("customer_type");

  useEffect(() => {
    if (customerType === "business") {
      setValue("enable_learning_request", false);
      setValue("enable_show_learning_request_claim_category", false);
      setValue("enable_mission", false);
      setValue("enable_qualification", false);
      setValue("enable_challenge", false);
    }
  }, [customerType]);

  useEffect(() => {
    if (learninghubList) {
      setValue("learninghub.status", learninghubList.status);
      setValue(
        "learninghub.domain",
        learninghubList?.domain || "https://codemi.learninghub.co.id"
      );
      const config = Object.keys(learninghubList.config).map((key) => {
        return {
          [key]: learninghubList.config[key],
        };
      });
      setLerninghubConfig(config);
      if (learninghubList.status === "active") {
        setLearninghubStatus("active");
      }
    }
  }, [learninghubList, setValue]);

  const handleLearninghubStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStatus = event.target.checked ? "active" : "inactive";
    console.log("🚀 ~ newStatus:", newStatus)
    setLearninghubStatus(newStatus);
    setValue("learninghub.status", newStatus);
  };

  console.log(configLearninghub);

  return (
    <Edit
      breadcrumb={false}
      canDelete={false}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={() => <></>}
    >
      <form>
        <Stack gap={2}>
          <Paper elevation={2}>
            <Card variant="outlined">
              <CardHeader title="Company Info" disableTypography={true} />
              <CardContent>
                <Grid container>
                  <Grid xs={12} sm={12} md={2}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="100%"
                    >
                      <Box
                        component="img"
                        sx={{
                          maxHeight: { xs: 167, md: 167 },
                          maxWidth: { xs: 150, md: 150 },
                        }}
                        src={queryResult?.data?.data.logo}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} md={5} padding={"1em"}>
                    <Stack spacing={3} direction="column">
                      <TextField
                        {...register("name")}
                        size="small"
                        id="name"
                        label="Name"
                        defaultValue={" "}
                        variant="outlined"
                        fullWidth={true}
                      />
                      <TextField
                        {...register("email")}
                        id="email"
                        label="Email"
                        variant="outlined"
                        defaultValue={" "}
                        fullWidth={true}
                        size="small"
                      />
                      <TextField
                        {...register("phone")}
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        defaultValue={" "}
                        fullWidth={true}
                        size="small"
                      />
                      <FormControl disabled fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={0}
                          label="Status"
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                      <div
                        style={{
                          border: "1px solid #626262",
                          borderRadius: "4px",
                        }}
                      >
                        <DatePicker
                          className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-1hz192s-MuiInputBase-input-MuiOutlinedInput-input"
                          selected={startDate}
                          onChange={(date: any) => {
                            setValue(
                              "expired_at",
                              moment(date)
                                .set({
                                  hour: 23,
                                  minute: 59,
                                  second: 59,
                                })
                                .format()
                            );
                            setStartDate(date);
                          }}
                          placeholderText="Expired Date"
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid xs={12} sm={12} md={5} padding={"1em"}>
                    <Stack spacing={3} direction="column">
                      <TextField
                        {...register("domain")}
                        id="domain"
                        label="Domain"
                        variant="outlined"
                        defaultValue={" "}
                        fullWidth={true}
                        size="small"
                      />
                      <TextField
                        {...register("other_domain")}
                        id="other_domain"
                        defaultValue={" "}
                        label="Other Domain"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                      />
                      <TextField
                        {...register("page_title")}
                        defaultValue={" "}
                        id="page_title"
                        label="Page Title"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                      />
                      <TextField
                        id="sub_title"
                        label="Sub Title"
                        defaultValue={""}
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                      />
                      <TextField
                        {...register("codemi_cs")}
                        id="codemi_cs"
                        label="CS Number"
                        variant="outlined"
                        defaultValue={"https://wa.me"}
                        fullWidth={true}
                        size="small"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>

          <Grid item md={12}>
            <Paper elevation={2}>
              <Card variant="outlined">
                <Grid xs={12} sm={12} md={12} padding={"1em"}>
                  <FormControlLabel
                    style={{ marginBottom: "1em" }}
                    control={
                      <Switch
                        checked={learninghubStatus === "active"}
                        {...register("learninghub.status")}
                        onChange={handleLearninghubStatusChange}
                      />
                    }
                    label="Learning Hub"
                  />
                  <Stack spacing={3} direction="column">
                    <TextField
                      disabled={learninghubStatus === "inactive"}
                      {...register("learninghub.domain")}
                      id="domain_learninghub"
                      label="Domain Learning Hub"
                      variant="outlined"
                      value={learninghubList?.domain}
                      defaultValue={"https://codemi.learninghub.co.id"}
                      fullWidth={true}
                      size="small"
                    />
                  </Stack>
                </Grid>
              </Card>
            </Paper>
          </Grid>

          {configLearninghub !== undefined && (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Learning Hub Flag Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper elevation={0}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          {configLearninghub?.map(
                            (item: any, index: number) => {
                              const key = Object.keys(item)[0];
                              const value = item[key];
                              return (
                                <Grid item xs={12} md={4} key={key}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...register(
                                          `learninghub.config.${key}`
                                        )}
                                        checked={value === "active"}
                                        onChange={(e) => {
                                          const newValue = e.target.checked
                                            ? "active"
                                            : "inactive";
                                          setValue(
                                            `learninghub.config.${key}`,
                                            newValue
                                          );
                                          const updatedConfig =
                                            configLearninghub.map(
                                              (config: any, idx: any) =>
                                                idx === index
                                                  ? { [key]: newValue }
                                                  : config
                                            );
                                          setLerninghubConfig(updatedConfig);
                                        }}
                                      />
                                    }
                                    label={key}
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </div>
          )}

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Flag Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={0}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container spacing={1}>
                        {!formLoading &&
                          config_list.map((item) => {
                            return (
                              <Grid item xs={12} md={4}>
                                {item === "enable_inspigo" ||
                                item === "enable_chat_and_co" ? (
                                  <div>
                                    <Checkbox
                                      disabled
                                      defaultChecked={
                                        queryResult?.data?.data[item] !== 0
                                      }
                                      style={{ paddingLeft: 0 }}
                                    />
                                    {item}
                                    <Select
                                      id={`${item}-select`}
                                      defaultValue={
                                        queryResult?.data?.data[item]
                                      }
                                      {...register(item)}
                                      style={{ marginLeft: 15 }}
                                    >
                                      <MenuItem value={0}>Inactive</MenuItem>
                                      <MenuItem value={1}>Web only</MenuItem>
                                      <MenuItem value={2}>Mobile only</MenuItem>
                                      <MenuItem value={3}>
                                        Web and mobile
                                      </MenuItem>
                                    </Select>
                                  </div>
                                ) : (
                                  <FormControlLabel
                                    id={item}
                                    control={
                                      <Checkbox
                                        defaultChecked={
                                          queryResult?.data?.data[item]
                                        }
                                        {...register(item)}
                                      />
                                    }
                                    label={item}
                                  />
                                )}
                              </Grid>
                            );
                          })}
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </div>

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Plan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={0}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container spacing={1}>
                        {!formLoading && (
                          <Select
                            sx={{ width: "30%" }}
                            id="insigo-select"
                            defaultValue={
                              queryResult?.data?.data?.customer_type
                            }
                            {...register("customer_type")}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setValue("customer_type", selectedValue);
                            }}
                          >
                            {planList.map((plan: any) => (
                              <MenuItem value={plan.slug}>{plan.name}</MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </div>

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="flag-content"
                id="panel-flag"
              >
                <Typography>User Details Settings</Typography>
              </AccordionSummary>
              {!formLoading && (
                <AccordionDetails>
                  <FormControlLabel
                    id="user_flag"
                    control={
                      <Switch
                        defaultChecked={
                          queryResult?.data?.data.enable_user_flag
                        }
                        {...register("enable_user_flag")}
                      />
                    }
                    label="Enable Additional User Detail"
                  />
                  {queryResult?.data?.data.enable_user_flag && (
                    <UserSettingList />
                  )}
                </AccordionDetails>
              )}
            </Accordion>
          </div>

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Chat & Co Plan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={0}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container spacing={1}>
                        {!formLoading && (
                          <Select
                            sx={{ width: "30%" }}
                            id="insigo-select"
                            disabled={
                              !queryResult?.data?.data.enable_chat_and_co
                            }
                            defaultValue={queryResult?.data?.data?.cnc_type}
                            {...register("cnc_type")}
                          >
                            {cncList.map((plan: any) => (
                              <MenuItem value={plan.slug}>{plan.name}</MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </div>

          {!formLoading && (
            <Paper elevation={1}>
              <Card variant="outlined">
                <CardHeader disableTypography title="SSO Settings" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card>
                        <CardContent>
                          <Typography
                            gutterBottom
                            marginBottom={"0.5em"}
                            variant="h6"
                            component="div"
                          >
                            Basic Authentication
                          </Typography>
                          <Stack spacing={2} direction="column">
                            <Typography variant="body2" color="text.secondary">
                              Most standard SSO Option
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  defaultChecked={
                                    queryResult?.data?.data.enable_codemi_login
                                  }
                                  {...register("enable_codemi_login")}
                                />
                              }
                              label="Enable Codemi Login"
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  defaultChecked={
                                    queryResult?.data?.data.enable_refresh_token
                                  }
                                  {...register("enable_refresh_token")}
                                />
                              }
                              label="Enable Refresh Token"
                            />
                            <TextField
                              {...register("token_expired")}
                              id="token_expired"
                              label="Token Expired Time"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Card>
                        <CardContent>
                          <Typography
                            gutterBottom
                            marginBottom={"0.5em"}
                            variant="h6"
                            component="div"
                          >
                            Azure AD Config
                          </Typography>
                          <Stack spacing={2} direction="column">
                            <Typography variant="body2" color="text.secondary">
                              Lizards are a widespread group of squamate
                              reptiles, with over 6,000 species, ranging across
                              all continents except Antarctica
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  defaultChecked={
                                    queryResult?.data?.data.enable_azure_login
                                  }
                                  {...register("enable_azure_login")}
                                />
                              }
                              label="Enable Azure Login"
                            />
                            <TextField
                              {...register("azure_ad_client_id")}
                              id="azure_ad_client_id"
                              label="Azure AD Client ID"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            />
                            <TextField
                              {...register("azure_ad_tenant_id")}
                              id="azure_ad_tenant_id"
                              label="Azure AD Tenant ID"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Card>
                        <CardContent>
                          <Typography
                            gutterBottom
                            marginBottom={"0.5em"}
                            variant="h6"
                            component="div"
                          >
                            Basic SSO Config
                          </Typography>
                          <Stack spacing={2} direction="column">
                            <Typography variant="body2" color="text.secondary">
                              Most standard SSO Option
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  defaultChecked={
                                    queryResult?.data?.data.enable_sso_login
                                  }
                                  {...register("enable_sso_login")}
                                />
                              }
                              label="Enable SSO Login"
                            />
                            <TextField
                              {...register("compinfo.sso_login_name")}
                              id="sso_login_name"
                              label="SSO Login Name"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            />
                            <TextField
                              {...register("sso_login_url")}
                              id="sso_login_url"
                              label="SSO Login URL"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            />
                            <TextField
                              {...register("sso_logout_url")}
                              id="sso_logout_url"
                              label="SSO Logout URL"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            />
                            {/* <TextField
                              {...register("external_login_config")}
                              id="external_login_config"
                              label="External Login Config"
                              variant="outlined"
                              defaultValue={" "}
                              fullWidth={true}
                              size="small"
                            /> */}
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <Typography
                            gutterBottom
                            marginBottom={"0.5em"}
                            variant="h6"
                            component="div"
                          >
                            Gmail SSO Config
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles,
                          </Typography>
                          <Stack spacing={2} direction="column">
                            <Typography variant="body2" color="text.secondary">
                              Most standard SSO Option
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  defaultChecked={
                                    queryResult?.data?.data.enable_gmail_login
                                  }
                                  {...register("enable_gmail_login")}
                                />
                              }
                              label="Enable Gmail Login"
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <Typography
                            gutterBottom
                            marginBottom={"0.5em"}
                            variant="h6"
                            component="div"
                          >
                            External Report
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles,
                            with over 6,000 species, ranging across all
                            continents except Antarctica
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          )}
        </Stack>
      </form>
    </Edit>
  );
};
