import { AccessControlProvider } from "@pankod/refine-core";

// AccessControlProvider function
export const accessControlProvider : AccessControlProvider = {
    can: async ({ resource, action, params }) => {
      const roles = localStorage && localStorage?.getItem("role");
  
      if (
        resource === "issues" &&
        action === "list" &&
        roles === "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      if (
        resource === "company" &&
        action === "list" &&
        roles === "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      if (
        resource === "research/invitelist" &&
        action === "list" &&
        roles === "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      if (
        resource === "research/queuelist" &&
        action === "list" &&
        roles === "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      if (
        resource === "course/courselist" &&
        action === "list" &&
        roles !== "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      if (
        resource === "article/articlelist" &&
        action === "list" &&
        roles !== "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
      if (
        resource === "feed/feedlist" &&
        action === "list" &&
        roles !== "content_auditor"
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
  
      return Promise.resolve({ can: true });
    },
  };
