import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
    background: "rgb(42, 19, 46)",
    backgroundSize: "cover",
};

export const titleStyles: CSSProperties = {
    textAlign: "center",
    fontSize: "24px",
    letterSpacing: "-0.04em",
    marginBottom: "24px",
    overflowWrap: "break-word",
    hyphens: "manual",
    textOverflow: "unset",
    whiteSpace: "pre-wrap",
};
