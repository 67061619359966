import { useTranslate } from "@pankod/refine-core";
import { Chip, ChipProps } from "@pankod/refine-mui";

type OrderStatusProps = {
    status?: "completed" | "not_started" | "done";
};

export const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
    const t = useTranslate();

    let color: ChipProps["color"];

    switch (status?.toLowerCase()) {
        case "not_started":
            color = "warning";
            break;
        case "completed":
            color = "success";
            break;
        case "done":
            color = "success";
            break;            
    }

    return (
        <Chip
            variant="filled"
            size="small"
            color={color}
            label={t(`${status}`)}
        />
    );
};
